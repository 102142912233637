.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 10px;
  min-width: 225px;
  cursor: pointer;
}

.card-container-disabled {
  cursor: unset;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 7px 12px;
  border-left: 5px solid #00a0ec;
  background: white;
  border-radius: 3px;
  width: 100%;
  box-shadow: 0px 1px 4px 0px #d4d4d5, 0px 0px 0px 0px #d4d4d500;
}

.card-content-disabled {
  height: 65px;
  background: #fbfbfb;
  border-left: 5px solid #00a0ec73;
  box-shadow: 0px 1px 4px 0px #d4d4d58a, 0px 0px 0px 0px #d4d4d500;
}

/* this is a cardinal sin and I'm so sorry */
.card-content-active {
  display: flex;
  flex-direction: column;
  padding: 7px 12px;
  border-left: 5px solid green;
  background: white;
  border-radius: 3px;
  width: 100%;
  box-shadow: 0px 1px 4px 0px #d4d4d5, 0px 0px 0px 0px #d4d4d500;
}

.image-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
  padding-left: 1px;
}

.image-detail {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
}

.checkbox {
  margin: 0 5px 0 1px;
}

.action-buttons {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .action-buttons {
    width: 100%;
    justify-content: left;
  }
}

.inbox-label-container {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 0.1em;
  margin: 30px 0 30px;
}

.inbox-label-inner {
  padding: 0 10px;
  background: #f6f8fb;
}
