.inbox-container {
  display: flex;
  flex-wrap: wrap-reverse;
}

.conversation-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-right: 1.5em;
}

.current-convo-container {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-convo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5.5em;
  color: rgba(0, 0, 0, 0.78);
}

.next-action-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 68px;
  top: 20px;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  margin: 1em 0em 1em 0;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .action-bar {
    justify-content: center;
    align-items: center;
    margin: 2em 0em 2em 0;
  }
  .top-container {
    margin-bottom: 1em;
    justify-content: space-between;
    width: 100%;
  }
  .conversation-list {
    flex: unset;
    width: 100%;
    margin-right: unset;
  }
}

.session-help-copy {
  display: flex;
}

.tooltip-icon {
  padding-left: 5px;
}