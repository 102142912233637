.topMenu {
  display: flex;
  flex-flow: row;
  padding: 8px;
  font-size: 1em;
  color: white;
  background-color: #044462;
  position: sticky;
  top: 0;
  overflow: none;
  width: 100%;
  justify-content: center;
  z-index: 1000;
  min-height: 42px;
}

.topMenuContent {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

@media (min-width: 1168px) {
  .topMenuContent {
    width: 100%;
    margin: 0px 10px;
  }
}

@media (max-width: 1167px) {
  .topMenuContent {
    width: 100%;
    margin: 0px 5px;
  }
}

.appGlobalsContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.leftHandContent,
.centeredFlex {
  display: flex;
  flex-flow: row;
  align-items: center;
}
