.loginContainer {
  height: 100%;
  width: 100%;
}

.centerColumn {
  max-width: 420px;
}

.ui.grid {
  margin: 0;
}

.umdHeader {
  max-width: 300px;
}
