.faqItemContainer {
  display: flex;
  flex-flow: row;
}

.faqContent {
  margin-left: 1em;
  display: flex;
  flex-flow: column;
  flex: 14;
}

.voteCount {
  font-size: 20px;
  font-weight: bold;
}

.voteContainer {
  display: flex;
  border-right: solid 1px lightgray;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.voted {
  background-color: blue;
  color: white;
}

.voteContent {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
  padding-right: 10px;
  height: 45px;
}

.addItemContent {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding-right: 10px;
  height: 45px;
}

.questionContainer {
  font-size: 18px;
  font-weight: bold;
}

.addFAQItemContainer {
  justify-content: center;
  align-items: center;
  color: #616161;
}

.answerContainer {
  margin-top: 0.5em;
}

.tagsContainer {
  margin-top: 0.5em;
}

.addTagsContainer {
  margin: 0.5em 0;
}
