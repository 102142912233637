.pushable {
  display: flex;
  overflow: hidden;
}

.pusher {
  display: flex;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

.sidebarHeaderContainer {
  background-color: #044462;
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 42px;
}

.sidebarHeaderContent {
  display: flex;
  justify-content: space-between;
  color: white;
  height: 22px;
  align-items: center;
  margin: 8px;
}

.sidebarNav {
  position: sticky;
  top: 0;
  padding: 1.25em;
  left: 0;
  background: white;
  box-shadow: 0px 0px 10px 0px #d4d4d5;
}

.sidebarCloseContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  width: 5em;
  height: 100%;
}
