/* The switch - the box around the slider */

/* We should add SASS */
:root {
  --circle-size: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.slider:before {
  position: absolute;
  content: '';
  height: var(--circle-size);
  width: var(--circle-size);
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

input:checked + .slider {
  background-color: #8fe1ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8fe1ff;
}

input:checked + .slider:before {
  left: 10px;
  -webkit-transform: translateX(var(--circle-size));
  -ms-transform: translateX(var(--circle-size));
  transform: translateX(var(--circle-size));
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleContainer {
  display: flex;
  flex-flow: row;
}

/* Label render prop */
.labelContainer {
  margin-left: 10px;
  line-height: 1.5;
  vertical-align: middle;
}
