/** Top level styles **/
html {
  position: relative;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  -webkit-overflow-scrolling: touch;
}

p {
  color: #464646;
}

.appContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  background: #f6f8fb;
}

#q-root {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Main body */
.centerContentBody {
  padding: 1em 2em;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
  overflow: auto;
}

.container {
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  overflow: auto;
  justify-content: space-between;
}

.rightRail {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border-left: 1px solid lightgray;
  width: 300px;
  /*overflow-y: scroll;*/
}

.rightRailCollapsed {
  display: flex;
  flex-flow: column-reverse;
  color: #636363;
  border-left: 1px solid lightgray;
  width: 3em;
  cursor: pointer;
  padding: 0.75em;
}

.rightRailContents {
  padding: 0.5em 1em;
}

.rightRailCollapse {
  cursor: pointer;
  color: #636363;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 0.75em 1em;
}

.loggedInBody {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  width: 100%;
  max-width: 1200px;
}

.unstyledList {
  list-style: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

/** Viewport breakpoints **/
/* Mobile */
@media (max-width: 768px) {
  #root {
    min-width: 100%;
  }
  .centerContentBody {
    padding: 1em 0.75em;
  }
}

/* Tablet */
@media (min-width: 768px) {
}

/* Desktop */
@media (min-width: 1024px) {
  .loggedInBody {
  }
}

/** Utilities **/

.clickable {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** Semantic hacks **/
.ui.table {
  border-spacing: 2px !important;
}

.ui.toggle.checkbox label {
  color: rgba(78, 51, 51, 0.8) !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #044462 !important;
}

/* This is a chonker of a hack, doesn't do anything rn: TODO fix */
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox {
  color: rgba(210, 210, 210, 1) !important;
}

.ui.card,
.ui.form {
  position: inherit !important;
}

body .ui.card,
body .ui.cards > .card {
  box-shadow: 0px 1px 4px 0px #d4d4d5, 0px 0px 0px 0px #d4d4d500;
}

button {
  border: none;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
}

.darkgray {
  color: #424242;
}
